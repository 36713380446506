import React from "react"
import { Store } from "../store"

const WithWorkplace = WrappedComponent => {
  const WrappedComponentWithRedux = props => {
    const data = React.useContext(Store)
    const [state, dispatch] = data.workplaces
    return (
      <WrappedComponent
        {...props}
        workPlaceState={state}
        workPlaceDispatch={dispatch}
      />
    )
  }
  return WrappedComponentWithRedux
}

export default WithWorkplace
