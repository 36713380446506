import React from 'react'
import { GoogleLogin } from 'react-google-login'
import get from 'lodash/get'
import CloudApi from 'cloudApi/api'
import withUser from 'hoc/withUser'
import withJob from 'hoc/withJob'
import googleApi from '../../../cloudApi/googleApi'
import { getProfile } from '../../../actions/auth'

const GoogleLoginComponent = props => {
  const { userDispatch, jobDispatch } = props
  const responseGoogle = (data, userDispatch, jobDispatch) => {
    const windowGlobal = typeof window !== 'undefined' && window
    const { localStorage } = windowGlobal
    return googleApi.getToken(data.code).then(googleResponse => {
      const tokenId = get(googleResponse, 'data.id_token')
      const refreshToken = get(googleResponse, 'data.refresh_token')
      localStorage.setItem('tokenId', tokenId)
      localStorage.setItem('refreshToken', refreshToken)
      return getProfile(userDispatch).then(() => {
        CloudApi.getJobsApplied().then(appliedJob =>
          jobDispatch({ type: 'FETCH_APPLIED_JOBS_DATA', payload: appliedJob })
        )
      })
    })
  }

  return (
    <GoogleLogin
      clientId={process.env.GOOGLE_AUTH_CLIENT_ID}
      onSuccess={data =>
        responseGoogle(data, userDispatch, jobDispatch)
      }
      onFailure={responseGoogle}
      prompt="consent"
      accessType="offline"
      approvalPrompt="force"
      uxMode="redirect"
      redirectUri={`${process.env.WEBSITE_URL}/login`}
      render={renderProps =>
        props.children({ googleLogin: renderProps.onClick })
      }
      cookiePolicy={'single_host_origin'}
    />
  )
}

export default withUser(withJob(GoogleLoginComponent))
