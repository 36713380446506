import React, { Component } from "react"
import { FormControl } from "baseui/form-control"
import cloudApi from "cloudApi/api"
import { Select } from "baseui/select"
import withWorkplace from "../../hoc/withWorkplace"
import GoogleMapReact from "google-map-react"
import Marker from "../../assets/Marker.png"

const PinMarker = () => <img alt={"marker"} src={Marker} width={30} />

class MapWithSearchBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lat: props.lat,
      lng: props.long,
    }
  }

  componentDidMount() {
    const { workPlaceState, workPlaceDispatch } = this.props
    if (workPlaceState.workplaces.length === 0) {
      return cloudApi.getWorkplaces().then(data => {
        workPlaceDispatch({
          type: "FETCH_WORKPLACES",
          payload: data.workplaces,
        })
      })
    }
  }

  selectWorkPlace = () => {
    const { workPlaceState, workplace } = this.props
    const workplaceToShow = workPlaceState.workplaces.find(
      item => item.thai_name === workplace
    )
    if (workplaceToShow) {
      return [workplaceToShow]
    }
    return null
  }

  render() {
    const { error, workPlaceState } = this.props
    return (
      <React.Fragment>
        {workPlaceState.workplaces.length > 0 && (
          <FormControl
            label="สถานที่ | Work Place"
            overrides={{
              ControlContainer: {
                style: {
                  marginBottom: 0,
                  position: "relative",
                },
              },
              Caption: {
                style: () => {
                  return {
                    position: "absolute",
                    top: "10%",
                    right: "8px",
                  }
                },
              },
            }}
            error={error}
          >
            <Select
              clearable={false}
              options={workPlaceState.workplaces}
              labelKey="thai_name"
              valueKey="id"
              value={
                workPlaceState.workplaces.length > 0
                  ? this.selectWorkPlace()
                  : null
              }
              onChange={event => this.props.handleChange(event.option)}
            />
          </FormControl>
        )}
        <div style={{ height: 400, width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.GOOGLE_MAP_API_KEY,
              libraries: ["places"],
            }}
            center={{
              lat: this.props.lat,
              lng: this.props.long,
            }}
            zoomControl={false}
            zoom={17}
          >
            <PinMarker lat={this.props.lat} lng={this.props.long} />
          </GoogleMapReact>
        </div>
      </React.Fragment>
    )
  }
}

export default withWorkplace(MapWithSearchBox)
