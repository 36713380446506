import React, { useState } from "react"
import { Input } from "baseui/input"
import { Label1 } from "baseui/typography"
import { KIND } from 'baseui/button'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from "baseui/modal"
import { FormControl } from "baseui/form-control"

const PhoneModal = ({
  toEditData,
  onClose,
  submit,
  showPhoneModal,
  loading,
}) => {
  const [phone, setPhone] = useState("")
  const [lineID, setLineID] = useState("")
  const [phoneError, setPhoneError] = useState(false)

  React.useEffect(() => {
    if(toEditData) {
      setPhone(toEditData.phone)
      setLineID(toEditData.lineId)
    }
  }, [toEditData])
  return (
    <Modal
      onClose={() => onClose()}
      isOpen={showPhoneModal}
      overrides={{
        Root: {
          style: () => {
            return {
              zIndex: 100,
            }
          },
        },
      }}
    >
      <ModalHeader>Edit Phone Number {`& Line ID`}</ModalHeader>
      <ModalBody>
        <FormControl label={() => "เบอร์โทรศัพท์"}>
          <Input
            onChange={event => {
              setPhone(event.target.value)
            }}
            placeholder="0xxxxxxxxx"
            value={phone}
            error={phoneError}
          />
        </FormControl>
        {phoneError && (
          <Label1 style={{ fontWeight: "bold", color: "red", marginTop: 20 }}>
            กรุณากรอกเบอร์โทรให้ถูกต้อง
          </Label1>
        )}
        <FormControl label={() => "Line ID"}>
          <Input
            onChange={event => {
              setLineID(event.target.value)
            }}
            placeholder="line ID"
            value={lineID}
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          kind={KIND.minimal}
          onClick={() => {
            onClose()
            setPhone("")
          }}
        >
          Close
        </ModalButton>
        <ModalButton
          disabled={
            !phone.match(
              /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
            ) || !lineID
          }
          autoFocus
          onClick={() => {
            if (phone.length !== 10) {
              setPhoneError(true)
            } else {
              submit(phone, lineID).then(() => {
                setPhone("")
                setLineID("")
              })
            }
          }}
          isLoading={loading}
        >
          Confirm
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

export default PhoneModal
