import React from "react"
import { Block } from "baseui/block"
import { StatefulMenu } from "baseui/menu"
import withUser from "hoc/withUser"
import withJob from "hoc/withJob"
import { navigate } from "gatsby"
import kmitlLogo from "../../../assets/images/kmitl-logo.png"
import kmitlgoLogo from "../../../assets/images/kmitlgo-logo.png"
import osdaLogo from "../../../assets/images/osda-logo.png"
import { Button, KIND } from "baseui/button"
import { Paragraph2 } from "baseui/typography"
import { GoogleLogout } from "react-google-login"
import { StatefulPopover } from "baseui/popover"
import { GoogleLoginComponent } from "components/molecules"
import ProfileAvatar from "components/atoms/ProfileAvatar"
import get from "lodash/get"

const windowGlobal = typeof window !== "undefined" && window
const { localStorage } = windowGlobal

const Profile = ({ userState, userDispatch, jobDispatch }) => {
  const role = get(userState, "user.role")
  const userEmail = get(userState, "user.email")
  const name = `${get(userState, "user.name")} ${get(
    userState,
    "user.family_name"
  )}`
  const img = get(userState, "user.img")

  const renderMenuByRole = () => {
    if (role === "professor") return "Job Track"
    if (role === "admin") return "Dashboard"
    return "Profile"
  }
  return (
    <StatefulPopover
      showArrow
      content={
        <GoogleLogout
          clientId={process.env.GOOGLE_AUTH_CLIENT_ID}
          hostedDomain={"kmitl.ac.th"}
          render={renderProps => (
            <StatefulMenu
              onItemSelect={({ item }) => {
                if (item.label === "Profile") return navigate("/profile/")
                else if (item.label === "Job Track")
                  return navigate("/job-track/")
                else if (item.label === "Dashboard")
                  return navigate("/post-job/")
                else {
                  jobDispatch({ type: "CLEAR_APPLIED_JOBS_DATA" })
                  userDispatch({ type: "CLEAR_USER_DATA" })
                  localStorage.removeItem("tokenId")
                  localStorage.removeItem("refreshToken")
                  renderProps.onClick()
                  return navigate("/")
                }
              }}
              overrides={{
                List: {
                  style: {
                    boxShadow: "none",
                    outline: "none",
                  },
                },
              }}
              items={[
                {
                  label: renderMenuByRole(),
                },
                { label: "Log Out" },
              ]}
            />
          )}
        />
      }
      accessibilityType={"tooltip"}
    >
      <Block display={"flex"}>
        <ProfileAvatar square invert name={name} size={"48px"} src={img} />
        {userEmail && (
          <Block
            width={"180px"}
            backgroundColor={"white"}
            padding={"8px"}
            height={"32px"}
            display={["none", "none", "flex", "flex"]}
            alignItems={"center"}
          >
            <Paragraph2
              textOverflow={"ellipsis"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
            >
              {userEmail}
            </Paragraph2>
          </Block>
        )}
      </Block>
    </StatefulPopover>
  )
}

const EnhancedProfile = withUser(withJob(Profile))

const Header = ({ jobDispatch }) => {
  return (
    <Block
      height={"48px"}
      justifyContent={"space-between"}
      alignItems={"center"}
      backgroundColor={"primary"}
      padding={"10px 30px"}
      display={"flex"}
    >
      <Block
        width={["65%", "65%", "50%", "20%"]}
        height={["75%", "75%", "100%", "100%"]}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <img
          src={kmitlLogo}
          alt="kmitl-logo"
          height={"100%"}
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
        />
        <img
          src={osdaLogo}
          alt="osda-logo"
          height={"100%"}
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
        />
        <img
          src={kmitlgoLogo}
          alt="kmitlgo-logo"
          height={"100%"}
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
        />
      </Block>
      {localStorage && localStorage.getItem("tokenId") ? (
        <EnhancedProfile />
      ) : (
        <GoogleLoginComponent>
          {({ googleLogin }) => {
            return (
              <Button
                onClick={googleLogin}
                kind={KIND.secondary}
                style={{ color: "black" }}
              >
                เข้าสู่ระบบ
              </Button>
            )
          }}
        </GoogleLoginComponent>
      )}
    </Block>
  )
}

export default Header
