import React from "react"
import { useEffect } from "react"
import { Select } from "baseui/select"
import CloudApi from "cloudApi/api"
import omit from "lodash/omit"
import get from "lodash/get"
import { Label1 } from "baseui/typography"

const FacultySelect = ({ onChange, value, error, disabled }) => {
  const [faculties, setFaculties] = React.useState([])

  useEffect(() => {
    const fetchFaculties = async () => {
      const data = await CloudApi.getDepartments()
      const facultyDataToSelect = []
      data.forEach(faculty => {
        facultyDataToSelect.push({
          ...omit(faculty, ["faculty"]),
          header: true,
          facultyName: `${faculty.group_thai_name} | ${faculty.group_english_name}`,
        })
        faculty.faculty.forEach(facultyData =>
          facultyDataToSelect.push({
            ...facultyData,
            facultyName: `${facultyData.thai_name} | ${facultyData.english_name}`,
          })
        )
      })
      setFaculties(facultyDataToSelect)
    }

    fetchFaculties()
  }, [])

  const renderValue = value => {
    if (typeof value === "string" && faculties.length > 0)
      return [faculties.find(job => job.faculty_id === value)]
    return value
  }

  return (
    <Select
      options={faculties}
      placeholder="คณะ"
      labelKey="facultyName"
      valueKey="faculty_id"
      error={error}
      onChange={event => {
        if (!get(event, "option.header")) {
          onChange(event.value)
        }
      }}
      getOptionLabel={({ option }) => {
        return get(option, "header") ? (
          <Label1 $style={{ fontWeight: "bold", fontSize: "18px" }}>
            {option.facultyName}
          </Label1>
        ) : (
          <>{option.facultyName}</>
        )
      }}
      disabled={disabled}
      value={value && faculties.length > 0 ? renderValue(value) : null}
      overrides={{
        DropdownListItem: {
          style: ({ children }) => {
            return {
              backgroundColor:
                typeof children.props.children.type !== "symbol"
                  ? "#EBEBEB"
                  : "",
            }
          },
        },
      }}
    />
  )
}

export default FacultySelect
