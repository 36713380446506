import React from "react"
import { Store } from "../store"

const WithJob = WrappedComponent => {
  const WrappedComponentWithRedux = props => {
    const data = React.useContext(Store)
    const jobState = data.jobs[0]
    const jobDispatch = data.jobs[1]
    return (
      <WrappedComponent
        {...props}
        jobState={jobState}
        jobDispatch={jobDispatch}
      />
    )
  }
  return WrappedComponentWithRedux
}

export default WithJob
