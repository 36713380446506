import api from "../cloudApi/api"

export const patchAppliedJob = (dispatch, jobId, userId, status) => {
  api.patchAppliedJobStatus(jobId, status, userId).then(() => {
    fetchUserInJob(dispatch, jobId)
    fetchJobTrackData(dispatch)
  })
}

export const fetchJobTrackData = (dispatch, offset = 0, limit) => {
  api
    .getPostedJob(offset, limit)
    .then(jobs => dispatch({ type: "FETCH_JOBS_TRACK_DATA", payload: jobs }))
}

export const fetchJobTrackUnPublish = (dispatch, offset = 0, limit) => {
  api
    .getPostedJobUnPublish(offset, limit)
    .then(jobs =>
      dispatch({ type: "FETCH_JOBS_TRACK_UN_PUBLISH_DATA", payload: jobs })
    )
}

export const fetchUserInJob = (dispatch, jobId) => {
  return api.getJobsAppliedUsersList(jobId).then(data => {
    dispatch({
      type: "ADD_USERS_IN_JOB_TRACK",
      payload: [{ id: jobId, users: data }],
    })
  })
}
