import React, { useState } from "react"
import moment from "moment"
import "moment/locale/th"
import { get, isArray } from "lodash"
import { Block } from "baseui/block"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from "baseui/modal"
import { styled } from "baseui"
import { Label1, Paragraph1, Label3, Paragraph2 } from "baseui/typography"
import Check from "baseui/icon/check"
import { Tag } from "baseui/tag"
import { StatefulTabs, Tab } from "baseui/tabs"
import GoogleMapReact from "google-map-react"
import { Button, KIND } from "baseui/button"
import CloudApi from "cloudApi/api"
import Marker from "../../../assets/Marker.png"
import withUser from "hoc/withUser"
import { GoogleLoginComponent } from "components/molecules"
import PhoneModal from "components/molecules/PhoneModal"

moment.locale("th")

const StyledPinMaker = styled("img", {
  width: "30px",
  position: "absolute",
  transform: "translate(-50%,-0%)",
  "@media only screen and (max-width: 425px)": { width: "20px" },
})

const PinMarker = () => <StyledPinMaker alt={"marker"} src={Marker} />

const JobDetail = ({
  job,
  jobTags,
  appliedJobs,
  getJobsApplied,
  userState,
  getJobDetail,
}) => {
  const {
    title,
    department,
    address,
    lat,
    long,
    description,
    contact,
    condition,
    start_time,
    end_time,
    Building,
    id,
    dates,
    date_type,
    quota,
    quota_count,
    applied_count,
  } = job
  const [showPhoneModal, setShowPhoneModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(null)
  const { user } = userState

  const checkButton = () => {
    if (appliedJobs.length > 0) {
      return appliedJobs.find(appliedJob => appliedJob.job_id === id)
    }
    return false
  }

  const appliedErrorMessage = conflict => {
    let label = ""
    if (conflict.type === "job") {
      label = "งาน"
    } else if (conflict.type === "class") {
      label = "วิชา"
    } else if (conflict.type === "class_mexam") {
      label = "การสอบกลางภาควิชา"
    } else if (conflict.type === "class_exam") {
      label = "การสอบปลายภาควิชา"
    }
    return `${label} ${conflict.name}`
  }

  const renderRegisterButton = () => {
    return (
      <GoogleLoginComponent>
        {({ googleLogin }) => {
          return checkButton() ? (
            <Button
              startEnhancer={() => (
                <Check
                  size={24}
                  style={{
                    display:
                      get(user, "role") === "professor" ? "none" : "block",
                  }}
                />
              )}
              overrides={{
                BaseButton: {
                  style: {
                    "@media only screen and (max-width: 600px)": {
                      width: "100%",
                      borderRadius: 0,
                    },
                  },
                },
              }}
            >
              สมัครแล้ว
            </Button>
          ) : (
            <Button
              kind={KIND.secondary}
              style={{
                display: get(user, "role") === "professor" ? "none" : "block",
              }}
              overrides={{
                BaseButton: {
                  style: {
                    "@media only screen and (max-width: 600px)": {
                      width: "100%",
                      borderRadius: 0,
                    },
                  },
                },
              }}
              onClick={() => {
                if (!localStorage.getItem("tokenId")) {
                  googleLogin()
                } else {
                  if (get(user, "phone")) {
                    return CloudApi.appliedJob(id)
                      .then(() => {
                        return Promise.all([getJobsApplied(), getJobDetail(id)])
                      })
                      .catch(err => {
                        if (err.data.conflict.length > 0) {
                          setErrorMessage(
                            `เนื่องจากวัน และเวลาตรงกับ${appliedErrorMessage(
                              err.data.conflict[0]
                            )}`
                          )
                        } else {
                          setErrorMessage("กรุณาลองใหม่อีกครั้ง")
                        }
                      })
                  } else setShowPhoneModal(true)
                }
              }}
            >
              สมัคร
            </Button>
          )
        }}
      </GoogleLoginComponent>
    )
  }

  const renderDate = () => {
    if (date_type === "none" && isArray(dates)) {
      return <Label1>สามารถเลือกวันทำงานเองได้</Label1>
    } else if (date_type === "range") {
      return (
        <Label1>
          {moment(dates[0]).format("DD MMM YYYY")} -{" "}
          {moment(dates[dates.length - 1]).format("DD MMM YYYY")}
        </Label1>
      )
    } else {
      return (
        <Label1>
          {isArray(dates) &&
            dates.map(
              (date, index) =>
                ` ${moment(date).format("DD MMM YYYY")}${
                  index === dates.length - 1 ? "" : ","
                }`
            )}
        </Label1>
      )
    }
  }

  return (
    <Block>
      <Block padding={["15px", "15px", "20px", "20px"]}>
        <Block
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
        >
          <Block>
            <Label1 style={{ fontWeight: "bold" }}>{title}</Label1>
            <Paragraph2 style={{ fontWeight: 300, marginTop: 8 }}>
              {department}
            </Paragraph2>
          </Block>
          <Block display={["none", "none", "block", "block"]}>
            {renderRegisterButton()}
            <Block marginTop={["10px", "15px"]}>
              <Label3>สมัครแล้ว: &nbsp;{applied_count}</Label3>
              <Label3>
                รับแล้ว: &nbsp;{quota_count} / {quota}
              </Label3>
            </Block>
          </Block>
        </Block>
        {jobTags &&
          jobTags.length &&
          jobTags.map((tag, index) => (
            <Tag
              key={index}
              closeable={false}
              variant={"outlined"}
              kind="primary"
              overrides={{
                Root: {
                  style: {
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                    borderBottomLeftRadius: "4px",
                    borderBottomRightRadius: "4px",
                  },
                },
              }}
            >
              {tag}
            </Tag>
          ))}
        <StatefulTabs
          initialState={{ activeKey: "0" }}
          overrides={{
            Block: { style: { marginTop: "20px" } },
            TabBar: {
              style: {
                backgroundColor: "white",
                width: "fit-content",
                marginTop: "20px",
              },
            },
          }}
        >
          <Tab
            title="รายละเอียด"
            overrides={{
              Tab: {
                style: ({ $theme, $active }) => {
                  return {
                    ":focus": { outlineWidth: "0px" },
                    color: $active ? $theme.colors.primary : $theme.tabColor,
                  }
                },
              },
            }}
          >
            <Block position="relative">
              <Paragraph1>
                <div
                  style={{ fontFamily: "Prompt" }}
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </Paragraph1>

              <Block display={["block", "block", "flex", "flex"]}>
                <Block width={"250px"}>
                  <Label1 style={{ fontWeight: "bold" }}>
                    เงื่อนไขการสมัคร:
                  </Label1>
                </Block>
                <Block>
                  <Paragraph1 style={{ whiteSpace: "pre-wrap", marginTop: 0 }}>
                    {condition && `- ${condition.replace(/\n/g, "\n- ")}`}
                  </Paragraph1>
                </Block>
              </Block>
              <Block
                display={["block", "block", "flex", "flex"]}
                marginTop={"20px"}
              >
                <Block width={"250px"}>
                  <Label1
                    overrides={{
                      "typo-label1": {
                        style: { marginTop: "20px", fontWeight: "bold" },
                      },
                    }}
                    style={{ fontWeight: "bold" }}
                  >
                    วันทำงาน:
                  </Label1>
                </Block>
                <Block>{renderDate()}</Block>
              </Block>
              <Block
                display={["block", "block", "flex", "flex"]}
                marginTop={"20px"}
              >
                <Block width={"250px"}>
                  <Label1
                    overrides={{
                      "typo-label1": {
                        style: { marginTop: "20px", fontWeight: "bold" },
                      },
                    }}
                    style={{ fontWeight: "bold" }}
                  >
                    เวลาทำงาน:
                  </Label1>
                </Block>
                <Block>
                  <Label1>
                    {start_time && moment(start_time).format("HH:mm")} -{" "}
                    {end_time && moment(end_time).format("HH:mm")}
                  </Label1>
                </Block>
              </Block>
              <Block
                display={["block", "block", "flex", "flex"]}
                marginTop={"20px"}
              >
                <Block width={"250px"}>
                  <Label1
                    overrides={{
                      "typo-label1": { style: { marginTop: "20px" } },
                    }}
                    style={{ fontWeight: "bold" }}
                  >
                    ติดต่อสอบถามรายละเอียด:
                  </Label1>
                </Block>
                <Block style={{ whiteSpace: "pre-wrap" }}>
                  <Paragraph1 style={{ whiteSpace: "pre-wrap", marginTop: 0 }}>
                    {contact}
                  </Paragraph1>
                </Block>
              </Block>
            </Block>
          </Tab>
          <Tab
            title="สถานที่"
            overrides={{
              Tab: {
                style: ({ $theme, $active }) => {
                  return {
                    ":focus": { outlineWidth: "0px" },
                    color: $active ? $theme.colors.primary : $theme.tabColor,
                  }
                },
              },
            }}
          >
            <Block display={["block", "block", "flex", "flex"]}>
              <Block width={["100%", "100%", "70%", "70%"]}>
                <Block
                  width={"100%"}
                  height={["165px", "165px", "260px", "260px"]}
                >
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: process.env.GOOGLE_MAP_API_KEY,
                    }}
                    center={{
                      lat: lat,
                      lng: long,
                    }}
                    zoomControl={false}
                    zoom={13}
                  >
                    <PinMarker lat={lat} lng={long} />
                  </GoogleMapReact>
                </Block>
              </Block>
              <Block
                width={["100%", "100%", "30%", "30%"]}
                paddingLeft={["0px", "0px", "20px", "20px"]}
                marginTop={["20px", "20px", "0px", "0px"]}
              >
                <Label1 style={{ fontWeight: "bold" }}>{Building}</Label1>
                <Paragraph1>{address}</Paragraph1>
              </Block>
            </Block>
          </Tab>
        </StatefulTabs>
      </Block>
      <Block display={["block", "block", "none", "none"]}>
        {renderRegisterButton()}
      </Block>
      <Modal
        onClose={() => setErrorMessage(null)}
        isOpen={errorMessage !== null}
        overrides={{
          Root: {
            style: () => {
              return {
                zIndex: 100,
              }
            },
          },
        }}
      >
        <ModalHeader>ไม่สามารถสมัครงานได้</ModalHeader>
        <ModalBody>{errorMessage}</ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={() => {
              setErrorMessage(null)
            }}
          >
            ตกลง
          </ModalButton>
        </ModalFooter>
      </Modal>
      <PhoneModal
        onClose={() => setShowPhoneModal(false)}
        showPhoneModal={showPhoneModal}
        loading={loading}
        submit={(phoneData, lineIdData) => {
          setLoading(true)
          CloudApi.updatePhoneUser(user.id, phoneData, lineIdData).then(() => {
            return CloudApi.appliedJob(id)
              .then(() => {
                setShowPhoneModal(false)
                getJobsApplied()
                setLoading(false)
              })
              .catch(err => {
                setShowPhoneModal(false)
                setLoading(false)
                if (err.data.conflict.length > 0) {
                  setErrorMessage(
                    `เนื่องจากวัน และเวลาตรงกับ${appliedErrorMessage(
                      err.data.conflict[0]
                    )}`
                  )
                } else {
                  setErrorMessage("กรุณาลองใหม่อีกครั้ง")
                }
              })
          })
        }}
      />
    </Block>
  )
}

export default withUser(JobDetail)
