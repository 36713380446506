import React, { useEffect, useState } from "react"
import get from "lodash/get"
import isObject from "lodash/isObject"
import { navigate } from "gatsby"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { Button } from "baseui/button"
import { FormControl } from "baseui/form-control"
import { Input } from "baseui/input"
import { Textarea } from "baseui/textarea"
import { Radio } from "baseui/radio"
import { RadioGroup, ALIGN } from "baseui/radio"
import { Select } from "baseui/select"
import { FacultySelect } from "../components/molecules"
import { TimePicker, StatefulDatepicker } from "baseui/datepicker"
import ArrowRight from "baseui/icon/arrow-right"
import { Label3, Caption1 } from "baseui/typography"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import qs from "qs"
import {
  EditorState,
  ContentState,
  convertToRaw,
} from 'draft-js'
import {
  addDays,
  eachWeekOfInterval,
  eachDayOfInterval,
  format,
} from "date-fns"
import { th } from "date-fns/locale"
import { Formik, Form } from "formik"
import cloudApi from "cloudApi/api"
import MapWithSelect from "../components/MapWithSelect/MapWithSelect"
import Header from "../components/molecules/Header"
import withUser from "hoc/withUser"
import checkPermission from "hoc/checkPermission"
import * as Yup from "yup"
import { fetchJobTrackData, fetchJobTrackUnPublish } from "../actions/job"
import withJobTrack from "../hoc/withJobTrack"
import { ArrowDown } from "baseui/icon"
import ErrorModal from "components/ErrorModal"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

const priceRate = {
  general: {
    rate: 50,
    rate_unit: "baht/hour",
  },
  ta: {
    rate: 200,
    rate_unit: "baht/hour",
  },
  baht: {
    rate: "",
    rate_unit: "baht",
  },
}

const JobSchema = Yup.object().shape({
  title: Yup.string().required("กรุณากรอกข้อมูล"),
  faculty: Yup.string().required("กรุณากรอกข้อมูล"),
  department: Yup.string().required("กรุณากรอกข้อมูล"),
  rate_unit: Yup.string().required("กรุณากรอกข้อมูล"),
  rate: Yup.number().required("กรุณากรอกข้อมูล"),
  quota: Yup.number()
    .moreThan(0)
    .required("กรุณากรอกข้อมูล"),
  description: Yup.object().required("กรุณากรอกข้อมูล"),
  condition: Yup.string().required("กรุณากรอกข้อมูล"),
  tags: Yup.array()
    .of(Yup.string())
    .required("กรุณากรอกข้อมูล"),
  start_time: Yup.date().required("กรุณากรอกข้อมูล"),
  end_time: Yup.date().required("กรุณากรอกข้อมูล"),
  address: Yup.string().required("กรุณากรอกข้อมูล"),
  lat: Yup.number().required("กรุณากรอกข้อมูล"),
  long: Yup.number().required("กรุณากรอกข้อมูล"),
  building: Yup.string().required("กรุณากรอกข้อมูล"),
  contact: Yup.string().required("กรุณากรอกข้อมูล"),
})

const JobForm = ({ location, jobsTrackState, jobsTrackDispatch }) => {

  const [, theme] = useStyletron()
  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(false)
  const [job, setJob] = useState(null)
  const [disabledButton, setDisabledButton] = useState(false)
  const [showCalendar, setShowCalendar] = useState(false)
  const [saveMode, setSaveMode] = useState(false)
  const [showError, setShowError] = useState(false)

  const getPriceType = rate => {
    if (rate === 50) {
      return "general"
    }
    if (rate === 200) {
      return "ta"
    }
    return "baht"
  }

  const genDraft = data => {
    if (data) {
      const blocksFromHtml = htmlToDraft(data)
      const { contentBlocks, entityMap } = blocksFromHtml
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      )
      return EditorState.createWithContent(contentState)
    }
  }

  const setJobData = (data, tagsData) => {
    const description = genDraft(data.description)
    setJob({
      ...data,
      tags:
        data.tags instanceof Array
          ? data.tags.map(tag => tagsData.find(tagData => tagData.name === tag))
          : [],
      dates:
        data.date_type === "weekly" || data.date_type === "range"
          ? [
              checkTypeDate(data.dates[0]),
              checkTypeDate(data.dates[data.dates.length - 1]),
            ]
          : data.dates.map(date => checkTypeDate(date)),
      description,
      priceType: getPriceType(data.rate),
      start_time: new Date(data.start_time),
      end_time: new Date(data.end_time),
    })
  }

  useEffect(() => {
    const fetchTags = async () => {
      const result = await cloudApi.getTags()
      setTags(result)
      const query = qs.parse(location.search, { ignoreQueryPrefix: true })
      if (query.jobId) {
        const { jobsTrack, unPublishJobs } = jobsTrackState
        const jobs = get(query, "public") === "true" ? jobsTrack : unPublishJobs
        if (jobs.length > 0 && get(query, "public", false)) {
          const item = jobs.find(jobData => jobData.id === query.jobId)
          if (item) {
            setJobData(item, result)
            setShowCalendar(true)
          }
        } else {
          cloudApi.getJobForEdit(query.jobId).then(job => {
            setJobData(job, result)
            setShowCalendar(true)
          })
        }
      } else {
        setShowCalendar(true)
      }
    }
    fetchTags()
  }, [])

  const checkTypeDate = item => {
    if (typeof item === "string") return new Date(item)
    return item
  }

  const checkFacultyData = faculty => {
    if (isObject(faculty)) {
      return faculty.faculty_id
    }
    return faculty
  }

  const submitJobs = (values, actions, useLoading) => {
    if (loading) {
      return
    }
    let dates = values.dates
    if (values.date_type === "weekly") {
      dates = eachWeekOfInterval(
        {
          start: checkTypeDate(values.dates[0]),
          end: checkTypeDate(values.dates[1]),
        },
        {
          weekStartsOn: checkTypeDate(values.dates[0]).getDay(),
        }
      )
    } else if (values.date_type === "range") {
      dates = eachDayOfInterval({
        start: checkTypeDate(values.dates[0]),
        end: checkTypeDate(values.dates[1]),
      })
    }

    const createdTags = []
    const selectedTags = []
    values.tags.forEach(tag =>
      tag.isCreatable ? createdTags.push(tag) : selectedTags.push(tag)
    )

    return Promise.all(
      createdTags.map(({ name }) => cloudApi.createTag(name))
    ).then(newTags => {
      const mergedTags = [
        ...selectedTags.map(({ id }) => id),
        ...newTags.map(({ id }) => id),
      ]
      const descriptionHTML = values.description
        ? draftToHtml(convertToRaw(values.description.getCurrentContent()))
        : null

      const data = {
        ...values,
        faculty: values.faculty ? checkFacultyData(values.faculty) : "",
        dates: dates.filter(date => date !== ""),
        tags: mergedTags,
        description: descriptionHTML || "",
        is_publish: !!actions,
      }

      const isCreate = !get(values, "id")
      useLoading && setLoading(true)
      if (isCreate) {
        return cloudApi.createJob(data).then(() => {
          useLoading && setLoading(false)
          actions && actions.setSubmitting(false)
          if (actions) {
            fetchJobTrackData(jobsTrackDispatch)
          } else {
            fetchJobTrackUnPublish(jobsTrackDispatch)
          }
          navigate(
            `/job-track/?${actions ? "publish=true" : "publish=false"}`
          )
        }).catch(() => {
            setShowError(true)
            actions && actions.setSubmitting(false)
          })
      } else {
        return cloudApi.editJob(data).then(() => {
          useLoading && setLoading(false)
          actions && actions.setSubmitting(false)
          if (actions) {
            fetchJobTrackData(jobsTrackDispatch)
          } else {
            fetchJobTrackUnPublish(jobsTrackDispatch)
          }
          navigate(
            `/job-track/?${actions ? "publish=true" : "publish=false"}`
          )
        }).catch(() => {
            setShowError(true)
            actions && actions.setSubmitting(false)
          })
      }
    })
  }

  return (
    <>
      <ErrorModal
        open={showError}
        onClose={() => setShowError(false)}
        header={'เกิดข้อผิดพลาด'}
        text={'กรุณาตรวจสอบข้อมูล และลองใหม่ในภายหลัง'}
      />
      <Header />
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={saveMode ? null : JobSchema}
        initialValues={
          job || {
            start_time: new Date(2019, 12, 31, 9, 0),
            end_time: new Date(2019, 12, 31, 12, 0),
            dates: [new Date(), addDays(new Date(), 1)],
            rate: 50,
            rate_unit: "baht/hour",
            date_type: "range",
            priceType: "general",
            lat: 13.7308,
            long: 100.7777,
            address: "สำนักงานอธิการบดี",
            tags: [],
          }
        }
        onSubmit={(values, actions) => {
          submitJobs(values, actions)
        }}
        render={props => {
          return (
            <Form>
              <Block
                display={"flex"}
                flexDirection={["column", "column", "row", "row"]}
                margin={`${theme.sizing.scale1200} auto`}
                width={["90%", "90%", "80%", "80%"]}
              >
                <Block
                  flex={1}
                  marginRight={[
                    0,
                    0,
                    theme.sizing.scale700,
                    theme.sizing.scale700,
                  ]}
                >
                  <FormControl
                    label="ชื่องาน | Job Title"
                    error={props.errors.title}
                  >
                    <Input
                      name="title"
                      value={props.values.title}
                      error={props.errors.title}
                      onChange={e => {
                        props.setFieldError("title", "")
                        props.handleChange(e)
                      }}
                    />
                  </FormControl>
                  <FormControl
                    label="คณะ/หน่วยงาน | Faculty/Office"
                    error={props.errors.faculty}
                  >
                    <FacultySelect
                      value={props.values.faculty}
                      error={props.errors.faculty}
                      onChange={value => {
                        props.setFieldError("faculty", "")
                        props.setFieldValue("faculty", value[0])
                      }}
                      disabled={
                        get(props, "values.id") &&
                        get(props, "values.is_publish")
                      }
                    />
                  </FormControl>
                  <Block flex={1}>
                    <FormControl
                      label="ภาควิชา/สาขาวิชา/หลักสูตร | Department/Branch/Course"
                      error={props.errors.department}
                    >
                      <Input
                        name="department"
                        value={props.values.department}
                        error={props.errors.department}
                        onChange={e => {
                          props.setFieldError("department", "")
                          props.handleChange(e)
                        }}
                        disabled={
                          get(props, "values.id") &&
                          get(props, "values.is_publish")
                        }
                      />
                    </FormControl>
                  </Block>
                  <Block
                    display={"flex"}
                    flexDirection={["column", "column", "row", "row"]}
                    alignItems={["initial", "initial", "center", "center"]}
                  >
                    <Block flex={1}>
                      <FormControl label="ค่าตอบแทน | Compensation">
                        <RadioGroup
                          disabled={
                            get(props, "values.id") &&
                            get(props, "values.is_publish")
                          }
                          onChange={e => {
                            if (priceRate[e.target.value].rate) {
                              props.setFieldError("rate", "")
                            }
                            props.setFieldValue(
                              "rate",
                              priceRate[e.target.value].rate
                            )
                            props.setFieldValue(
                              "rate_unit",
                              priceRate[e.target.value].rate_unit
                            )
                            props.handleChange(e)
                          }}
                          value={props.values.priceType}
                          align="horizontal"
                          name="priceType"
                          overrides={{
                            RadioGroupRoot: {
                              style: {
                                display: "flex",
                                justifyContent: "space-between",
                              },
                            },
                          }}
                        >
                          <Radio value="general">ทั่วไป</Radio>
                          <Radio value="ta">TA</Radio>
                          <Radio value="baht">เหมาจ่าย</Radio>
                        </RadioGroup>
                      </FormControl>
                      <FormControl error={props.errors.rate}>
                        <Input
                          disabled={!(props.values.rate_unit === "baht")}
                          type={"number"}
                          name={"rate"}
                          error={props.errors.rate}
                          startEnhancer="฿"
                          endEnhancer={
                            props.values.rate_unit === "baht" ? (
                              ""
                            ) : (
                              <Block width={"60px"}>/ ชั่วโมง</Block>
                            )
                          }
                          value={props.values.rate}
                          onChange={e => {
                            props.setFieldError("rate", "")
                            props.handleChange(e)
                          }}
                        />
                      </FormControl>
                    </Block>
                  </Block>
                  <FormControl
                    label="จำนวนที่รับ | Quota"
                    error={props.errors.quota}
                  >
                    <Input
                      type={"number"}
                      name={"quota"}
                      value={props.values.quota}
                      error={props.errors.quota}
                      onChange={e => {
                        props.setFieldError("quota", "")
                        props.handleChange(e)
                      }}
                      disabled={
                        get(props, "values.id") &&
                        get(props, "values.is_publish")
                      }
                    />
                  </FormControl>
                  <FormControl
                    label="รายละเอียด | Description"
                    error={!job && props.errors.description}
                  >
                    <Editor
                      name={"description"}
                      wrapperStyle={{
                        fontFamily: "prompt",
                      }}
                      editorStyle={{
                        backgroundColor:
                          !job && props.errors.description
                            ? theme.colors.inputFillError
                            : `rgb(247, 247, 247)`,
                        border: `2px solid ${
                          !job && props.errors.description
                            ? theme.colors.inputBorderError
                            : `rgb(247, 247, 247)`
                        } `,
                        paddingTop: 0,
                        padding: 10,
                        minHeight: 300,
                      }}
                      editorState={props.values.description}
                      onEditorStateChange={data => {
                        props.setFieldValue("description", data)
                      }}
                    />
                  </FormControl>
                  <FormControl
                    label="เงื่อนไขการสมัคร | Application Conditions"
                    error={props.errors.condition}
                  >
                    <Textarea
                      id="condition"
                      name={"condition"}
                      value={props.values.condition}
                      error={props.errors.condition}
                      onChange={e => {
                        props.setFieldError("condition", "")
                        props.handleChange(e)
                      }}
                    />
                  </FormControl>
                </Block>
                <Block
                  flex={1}
                  marginLeft={[
                    0,
                    0,
                    theme.sizing.scale700,
                    theme.sizing.scale700,
                  ]}
                >
                  <FormControl label="ประเภท | Type" error={props.errors.tags}>
                    <Select
                      multi
                      options={tags}
                      creatable
                      value={props.values.tags}
                      error={props.errors.tags}
                      labelKey="name"
                      valueKey="id"
                      onChange={({ value }) => {
                        props.setFieldError("tags", "")
                        props.setFieldValue("tags", value)
                      }}
                    />
                  </FormControl>
                  <Block display={"flex"} alignItems={"flex-end"}>
                    <Block flex={1}>
                      <FormControl
                        label="ช่วงเวลา | Time Range"
                        error={props.errors.start_time}
                      >
                        <TimePicker
                          name="start_time"
                          format={"24"}
                          step={1800}
                          value={props.values.start_time}
                          error={props.errors.start_time}
                          onChange={time =>
                            props.setFieldValue("start_time", time)
                          }
                          disabled={
                            get(props, "values.id") &&
                            get(props, "values.is_publish")
                          }
                        />
                      </FormControl>
                    </Block>
                    <Block
                      flex={0.1}
                      display={"flex"}
                      justifyContent={"center"}
                      margin={`${theme.sizing.scale1200} auto auto`}
                    >
                      <ArrowRight size={24} />
                    </Block>
                    <Block flex={1}>
                      <FormControl label=" " error={props.errors.end_time}>
                        <TimePicker
                          name="end_time"
                          format={"24"}
                          step={1800}
                          value={props.values.end_time}
                          error={props.errors.end_time}
                          onChange={time =>
                            props.setFieldValue("end_time", time)
                          }
                          disabled={
                            get(props, "values.id") &&
                            get(props, "values.is_publish")
                          }
                        />
                      </FormControl>
                    </Block>
                  </Block>
                  <Block
                    display="flex"
                    flexDirection={["column", "column", "row", "row"]}
                  >
                    <Block flex={1}>
                      <FormControl label="วันที่ | Date">
                        <RadioGroup
                          onChange={e => {
                            if (e.target.value === "none") {
                              props.setFieldValue("dates", [])
                            } else if (e.target.value === "date") {
                              if (
                                props.values.date_type === "date" &&
                                get(job, "id")
                              ) {
                                props.setFieldValue("dates", [job.dates[0], ""])
                              } else {
                                props.setFieldValue("dates", [new Date().setHours(0,0,0,0), ""])
                              }
                            } else if (
                              e.target.value === "weekly" &&
                              get(job, "id") &&
                              (get(job, "date_type") === "range" ||
                                get(job, "date_type") === "weekly")
                            ) {
                              props.setFieldValue("dates", [
                                job.dates[0],
                                job.dates[job.dates.length - 1],
                              ])
                            } else if (
                              e.target.value === "range" &&
                              get(job, "id") &&
                              (get(job, "date_type") === "range" ||
                                get(job, "date_type") === "weekly")
                            ) {
                              props.setFieldValue("dates", [
                                job.dates[0],
                                job.dates[job.dates.length - 1],
                              ])
                            } else {
                              props.setFieldValue("dates", [
                                new Date(),
                                addDays(new Date(), 7),
                              ])
                            }
                            props.handleChange(e)
                          }}
                          value={props.values.date_type}
                          align={ALIGN.vertical}
                          name="date_type"
                          overrides={{
                            RadioGroupRoot: {
                              style: {
                                display: "flex",
                                justifyContent: "space-between",
                              },
                            },
                          }}
                          disabled={
                            get(props, "values.id") &&
                            get(props, "values.is_publish")
                          }
                        >
                          <Radio value="range">รายวัน</Radio>
                          <Radio value="weekly">รายสัปดาห์</Radio>
                          <Radio value="date">เลือกวันเอง</Radio>
                          <Radio value="none">ไม่กำหนดวัน</Radio>
                        </RadioGroup>
                      </FormControl>
                    </Block>
                    {props.values.date_type === "date" && showCalendar && (
                      <Block flex={1.5}>
                        <Label3 marginTop={"8px"} marginBottom={"8px"}>
                          เลือกวันที่ | Selected Date
                        </Label3>
                        {props.values.dates &&
                          props.values.dates.map((date, index) => (
                            <Block
                              marginBottom={theme.sizing.scale600}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Caption1 flex={0.2}>วันที่ {index + 1}</Caption1>
                              <Block flex={1}>
                                <StatefulDatepicker
                                  placeholder={"Add Date"}
                                  excludeDates={props.values.dates}
                                  minDate={new Date().setHours(0,0,0,0)}
                                  monthsShown={2}
                                  initialState={{ value: date }}
                                  onChange={({ date }) => {
                                    props.setFieldValue(`dates[${index}]`, date)
                                    if (
                                      index ===
                                      props.values.dates.length - 1
                                    ) {
                                      props.setFieldValue(
                                        `dates[${index + 1}]`,
                                        ""
                                      )
                                    }
                                  }}
                                  disabled={
                                    get(props, "values.id") &&
                                    get(props, "values.is_publish")
                                  }
                                />
                              </Block>
                            </Block>
                          ))}
                      </Block>
                    )}
                    {props.values.date_type === "weekly" && showCalendar && (
                      <Block
                        display={"flex"}
                        flex={1.5}
                        flexDirection={"column"}
                      >
                        <Block>
                          <FormControl label="วันแรก | First Date">
                            <StatefulDatepicker
                              minDate={new Date()}
                              monthsShown={2}
                              initialState={{ value: props.values.dates[0] }}
                              onChange={({ date }) => {
                                props.setFieldValue("dates[0]", date)
                              }}
                              disabled={
                                get(props, "values.id") &&
                                get(props, "values.is_publish")
                              }
                            />
                          </FormControl>
                        </Block>
                        <Block
                          flex={0.1}
                          display={"flex"}
                          justifyContent={"center"}
                        >
                          <ArrowDown size={24} />
                        </Block>
                        <Block>
                          <FormControl
                            label="วันสิ้นสุด | Last Date"
                            caption={`ทุกวัน ${format(
                              props.values.dates[0],
                              "eee",
                              { locale: th }
                            )} ตั้งแต่วันที่ ${format(
                              props.values.dates[0],
                              "PP",
                              { locale: th }
                            )} ถึง ${format(props.values.dates[1], "PP", {
                              locale: th,
                            })} (${
                              props.values.dates[0] < props.values.dates[1]
                                ? eachWeekOfInterval(
                                    {
                                      start: props.values.dates[0],
                                      end: props.values.dates[1],
                                    },
                                    {
                                      weekStartsOn: checkTypeDate(
                                        props.values.dates[0]
                                      ).getDay(),
                                    }
                                  ).length
                                : 0
                            } วัน)`}
                          >
                            <StatefulDatepicker
                              minDate={new Date()}
                              monthsShown={2}
                              initialState={{ value: props.values.dates[1] }}
                              onChange={({ date }) => {
                                props.setFieldValue("dates[1]", date)
                              }}
                              disabled={
                                get(props, "values.id") &&
                                get(props, "values.is_publish")
                              }
                            />
                          </FormControl>
                        </Block>
                      </Block>
                    )}
                    {props.values.date_type === "range" && showCalendar && (
                      <Block flex={1.5}>
                        <FormControl label="ช่วงวันที่ | Date Range">
                          <StatefulDatepicker
                            minDate={new Date()}
                            monthsShown={2}
                            range
                            initialState={{
                              value: [
                                props.values.dates[0],
                                props.values.dates[
                                  props.values.dates.length - 1
                                ],
                              ],
                            }}
                            onChange={({ date }) => {
                              props.setFieldValue("dates", [])
                              props.setFieldValue("dates[0]", date[0])
                              props.setFieldValue("dates[1]", date[1])
                            }}
                            disabled={
                              get(props, "values.id") &&
                              get(props, "values.is_publish")
                            }
                          />
                        </FormControl>
                      </Block>
                    )}
                  </Block>
                  <MapWithSelect
                    error={props.errors.address}
                    handleChange={({ thai_name, lat, lng }) => {
                      props.setFieldError("address", "")
                      props.setFieldValue("address", thai_name)
                      props.setFieldValue("lat", lat)
                      props.setFieldValue("long", lng)
                    }}
                    lat={props.values.lat}
                    long={props.values.long}
                    workplace={props.values.address || "สำนักงานอธิการบดี"}
                  />
                  <FormControl
                    label="ห้อง / ชั้น | Room / Floor"
                    error={props.errors.building}
                  >
                    <Input
                      name="building"
                      value={props.values.building}
                      error={props.errors.building}
                      onChange={e => {
                        props.setFieldError("building", "")
                        props.handleChange(e)
                      }}
                    />
                  </FormControl>
                  <FormControl
                    label="ข้อมูลติดต่อ | Contact Info"
                    error={props.errors.contact}
                  >
                    <Textarea
                      id="contact"
                      name={"contact"}
                      value={props.values.contact}
                      error={props.errors.contact}
                      onChange={e => {
                        props.setFieldError("contact", "")
                        props.handleChange(e)
                      }}
                    />
                  </FormControl>
                  <Block display="flex" justifyContent="space-between">
                    <Button
                      isLoading={loading}
                      style={{
                        width: "45%",
                        display: get(props, "values.is_publish")
                          ? "none"
                          : "flex",
                      }}
                      overrides={{
                        BaseButton: {
                          style: ({ $theme }) => {
                            return {
                              backgroundColor: "white",
                              color: $theme.colors.primary,
                              border: `1px solid ${$theme.colors.primary}`,
                            }
                          },
                        },
                      }}
                      onClick={() => {
                        if (props.values.title) {
                          setDisabledButton(true)
                          setSaveMode(true)
                          submitJobs(props.values, false, true)
                        } else {
                          props.setFieldError("title", "กรุณาใส่ข้อมูล")
                        }
                      }}
                    >
                      บันทึก | SAVE
                    </Button>
                    <Button
                      isLoading={props.isSubmitting}
                      type={"submit"}
                      style={{
                        width: get(props, "values.is_publish") ? "100%" : "45%",
                      }}
                      disabled={disabledButton}
                    >
                      {get(props, "values.id") &&
                      get(props, "values.is_publish")
                        ? "แก้ไข | EDIT"
                        : "ประกาศงาน | PUBLISH"}
                    </Button>
                  </Block>
                </Block>
              </Block>
            </Form>
          )
        }}
      />
    </>
  )
}

export default withUser(withJobTrack(checkPermission(["professor"], JobForm)))
