import React from "react"
import { Modal, ModalHeader, ModalBody } from "baseui/modal"
import { Label1 } from "baseui/typography"

const ErrorModal = ({ header, text, onClose, open }) => {
  return (
    <Modal onClose={() => onClose()} isOpen={open}>
      <ModalHeader>{header}</ModalHeader>
      <ModalBody>
        <Label1>{text}</Label1>
      </ModalBody>
    </Modal>
  )
}

export default ErrorModal
