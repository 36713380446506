import React from "react"
import { Store } from "../store"

const withJobTrack = WrappedComponent => {
  const WrappedComponentWithRedux = props => {
    const data = React.useContext(Store)
    const [ state, dispatch ] = data.jobsTrack
    return (
      <WrappedComponent
        {...props}
        jobsTrackState={state}
        jobsTrackDispatch={dispatch}
      />
    )
  }
  return WrappedComponentWithRedux
}

export default withJobTrack
